<template>
    <v-row justify="center">
        <v-dialog
                v-model="show"
                persistent
                width="auto"
        >
            <v-card>
                <v-card-title class="text-h5" color="red-darken-3">
                    Game will be removed
                </v-card-title>
                <v-card-text>Game name: {{name}}, game id: {{id}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon="mdi-close"
                            color="green-darken-1"
                            @click="close"
                            :loading="isLoading"
                    />
                    <v-btn
                            color="red-darken-3"
                            icon="mdi-delete"
                            @click="remove"
                            :loading="isLoading"
                    />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        name: "removeGameDialog",
        data: () => ({
            isLoading: false,
            show: false,
            name:'',
            id: '',
            type: '',
            _id: null,
        }),
        methods: {
            showDialog(data){
                this.isLoading = false;
                ({name: this.name, gameId: this.id, gameType: this.type, _id: this._id} = data);
                this.show = true
            },
            async remove(){
                this.isLoading = true;
                this.$emit('removeGame', {_id: this._id})

            },
            close(){
                this.isLoading = false;
                this.name = '';
                this.id = '';
                this.type ='';
                this.show = false;
            }
        }
    }
</script>

<style scoped>

</style>
