<template>
    <v-alert
            class="ma-8"
            position="absolute"
            v-model="alert"
            closable
            variant="outlined"
            elevation="6"
            :text="error"
            type="error"
    />
    <div class="login content_wrap">
        <v-card width="400" class="form" elevation="6">
            <v-form ref="form" class="pa-8" >
                <v-text-field
                        v-model="username"
                        label="Username"
                        clearable
                        :rules="rules"
                ></v-text-field>

                <v-text-field
                        v-model="password"
                        label="Password"
                        type="password"
                        clearable
                        :rules="rules"
                ></v-text-field>

                <v-btn block class="mt-2" :loading="loading" @click="loginMethod" color="green-darken-1">Login</v-btn>
            </v-form>
        </v-card>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'loginView',
        data: () => ({
            username: null,
            password: null,
            loading: false,
            alert: false,
            error: null,
            rules: [
                v => !!v || 'field is required',
            ],
        }),
        methods: {
            ...mapActions(["login"]),
            async loginMethod(){
                this.alert = false;
                const { valid } = await this.$refs.form.validate();
                if(valid){
                    this.loading = true;
                    const res = await this.login({
                        login: this.username,
                        password: this.password
                    });
                    this.loading = false;
                    if(Number(res.code) !== 200 && res.message){
                        this.error = res.message;
                        this.alert = true
                    }else{
                        this.$router.push('/games')
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                'access',
                'refresh'
            ]),
        },
        mounted() {

        }
    }
</script>

<style lang="scss">
    .content_wrap {
        margin: 10px 25px;
    }
    .login{
        .form{
            margin: auto;
            margin-top: 20vh;
        }
    }
</style>
