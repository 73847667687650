<template>
    <v-row justify="center">
        <v-dialog
                v-model="show"
                persistent
                width="1024"
        >
            <v-form ref="form">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{isEdit?"Edit":"New"}} Game</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            :rules="nameRules"
                                            v-model="name"
                                            label="Name*"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6"
                                >
                                    <v-text-field
                                            :rules="nameRules"
                                            v-model="id"
                                            label="Id*"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6"
                                >
                                    <v-select
                                            :rules="nameRules"
                                            v-model="type"
                                            :items="['slot']"
                                            label="Type*"
                                            required
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                icon="mdi-close"
                                color="red-darken-3"
                                @click="close"
                                :loading="isLoading"
                        />
                        <v-btn
                                color="green-darken-1"
                                icon="mdi-content-save"
                                @click="save"
                                :loading="isLoading"
                        />
                    </v-card-actions>
                </v-card>
            </v-form>

        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        name: "addGameDialog",
        data: () => ({
            isLoading: false,
            isEdit: false,
            show: false,
            name:'',
            id: '',
            type: '',
            _id: null,
            nameRules: [
                value => {
                    if (value) return true;
                    return 'required.'
                }
            ],
        }),
        methods: {
            showDialog(isEdit = false, data){
                this.isLoading = false;
                this.isEdit = isEdit;
                if(isEdit){
                    ({name: this.name, gameId: this.id, gameType: this.type, _id: this._id} = data);
                }
                this.show = true
            },
            async save(){
                const { valid } = await this.$refs.form.validate();
                if(valid){
                    this.isLoading = true;
                    if(this.isEdit){
                        this.$emit('updateGame', {name: this.name, id: this.id, type: this.type, _id: this._id})
                    }else{
                        this.$emit('saveGame', {name: this.name, id: this.id, type: this.type})
                    }
                }
            },
            close(){
                this.isLoading = false;
                this.name = '';
                this.id = '';
                this.type ='';
                this.show = false;
            }
        }
    }
</script>

<style scoped>

</style>
