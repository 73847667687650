import { createRouter, createWebHistory } from 'vue-router'
import GamesView from '../views/GamesView';
import LoginView from "@/views/LoginView";
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/games',
    name: 'games',
    component: GamesView
  },
  {
    path: '/servers',
    name: 'servers',
    component: () => import(/* webpackChunkName: "servers" */ '../views/ServersView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "servers" */ '../views/SettingsView.vue')
  },
  {
    path: '/translations',
    name: 'translations',
    component: () => import(/* webpackChunkName: "servers" */ '../views/TranslationsView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if(to.path !== '/'){
    const token = localStorage.getItem('access');
    if (!token) next ({ path: '/' });
    else next();
  }else{
    next();
  }
});

export default router
