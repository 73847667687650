import axios from "axios";
import {setAxiosDefaultToken} from "@/services/request";

export const loginModule = {
    state: () => ({
        access: null,
        refresh: null,
        isLogged: false
    }),
    getters: {
        isLogged: state => state.isLogged,
        access: state => {
            if(!state.access){
                const access = localStorage.getItem("access");
                if(access){
                    state.access = access
                }
            }
            return state.access
        },
        refresh: state => {
            if(!state.refresh){
                const refresh = localStorage.getItem("refresh");
                if(refresh){
                    state.refresh = refresh
                }
            }
            return state.refresh
        }
    },
    mutations: {
        setTokens(state, tokens){
            if(tokens.access){
                state.access = tokens.access;
                localStorage.setItem('access', state.access);
                setAxiosDefaultToken(state.access)
            }
            if(tokens.refresh){
                state.refresh = tokens.refresh;
                localStorage.setItem('refresh', state.refresh);
            }
        },
        resetTokens(state){
            state.access = null;
            state.refresh = null;
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
        },
        setLogged(state){
            state.isLogged = true
        },
        setUnLogged(state){
            state.isLogged = false
        }
    },
    actions: {
        async login({commit}, data) {
            try{
                let response = await axios.post(
                    `auth/login`,
                    data
                );
                commit('setTokens', response.data);
                commit('setLogged');
                return response.data
            }catch (e) {
                console.log(e);
                return {message: e.response.data.error, code: e.response.data.statusCode}
            }
        },
        async refresh({commit}, data) {
            try{
                let response = await axios.post(
                    `auth/refresh`,
                    data
                );
                commit('setTokens', response.data);
                commit('setLogged');
                return response.data
            }catch (e) {
                console.log(e);
                return {message: e.response.data.error, code: e.response.data.statusCode}
            }
        },
        async logout({commit}, data) {
            try{
                let response = await axios.post(
                    `auth/logout`,
                    data
                );
                commit('resetTokens');
                commit('setUnLogged');
                return response.data
            }catch (e) {
                commit('resetTokens');
                commit('setUnLogged');
                console.log(e);
                return {message: e.response.data.error, code: e.response.data.statusCode}
            }
        },
    }
};
