import axios from "axios";
import { findIndex } from 'lodash'

export const settingsModule = {
    state: () => ({
        settings: []
    }),
    getters: {
        settings: state => state.settings
    },
    mutations: {
        addSettings(state, settings){
            state.settings = settings
        },
        addNewSettings(state, settings){
            state.settings.push(settings)
        },
        updateSettings(state, data) {
            let oldSettingsIndex = findIndex(state.settings, settings=>settings._id === data._id);
            state.settings[oldSettingsIndex] = data;
        },
        removeSettings(state, data){
            let oldSettingsIndex = findIndex(state.settings, settings=>settings._id === data._id);
            state.settings.splice(oldSettingsIndex, 1);
        },
        addSetting(state, setting){
            let oldSettingsIndex = findIndex(state.settings, settings=>settings._id === setting._id);
            if(oldSettingsIndex>=0){
                state.settings[oldSettingsIndex] = setting
            }
        },
        removeSetting(state, data){
            let oldSettingsIndex = findIndex(state.settings, settings=>settings._id === data._id);
            if(oldSettingsIndex>=0){
               const settingIndex = findIndex(state.settings[oldSettingsIndex].settings, set => set.key ===data.key)
               if(settingIndex>=0){
                   state.settings[oldSettingsIndex].settings.splice(settingIndex, 1)
               }
            }
        },

    },
    actions: {
        async getSettingsData({commit, getters}) {
            if(getters.currentGame){
                try{
                    let response = await axios.get(
                        `settings/${getters.currentGame._id}`
                    );
                    commit('addSettings', response.data)
                }catch (e) {
                    console.log(e);
                }
            }else{
                console.log('no game selected')
            }
        },
        async saveSettings({commit, getters}, data){
            let response;
            try{
                response = await axios.post(
                    `settings/${getters.currentGame._id}`,
                    {
                        serverId: data.serverId
                    }
                );
                commit('addNewSettings', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async saveSetting({commit, getters}, data){
            let response;
            try{
                response = await axios.post(
                    `settings/${getters.currentGame._id}`,
                    {
                        serverId: data.serverId,
                        settings: data.settings
                    }
                );
                commit('addSetting', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async updateSettings({commit}, data){
            let response;
            try{
                response = await axios.put(
                    `settings/${data._id}`,
                    {
                        serverId: data.id,
                    }
                );
                commit('updateServer', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async removeSettings({commit}, data){
            let response;
            try{
                response = await axios.delete(
                    `settings/${data._id}`
                );
                commit('removeSettings', data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async removeSetting({commit}, data){
            let response;
            try{
                response = await axios.post(
                    `settings/setting/${data._id}`,
                    {
                        key: data.key
                    }
                );
                commit('removeSetting', data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
    },
};
