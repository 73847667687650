import axios from "axios";
import { findIndex } from 'lodash'

export const gamesModule = {
    state: () => ({
        games: [],
        currentGame: null
    }),
    getters: {
        games: state => state.games,
        currentGame: state => {
            if(!state.currentGame){
                const game = localStorage.getItem("currentGame");
                if(game){
                    state.currentGame = JSON.parse(game)
                }
            }
            return state.currentGame
        }
    },
    mutations: {
        addGames(state, games){
            state.games = games
        },
        addGame(state, game){
            state.games.push(game)
        },
        updateGame(state, data) {
            let oldGameIndex = findIndex(state.games, game=>game._id === data._id);
            state.games[oldGameIndex] = data;
        },
        removeGame(state, data){
            let oldGameIndex = findIndex(state.games, game=>game._id === data._id);
            state.games.splice(oldGameIndex, 1);
        },
        selectGame(state, data){
            state.currentGame = data;
            localStorage.setItem("currentGame", JSON.stringify(data))
        }
    },
    actions: {
        async getGamesData({commit}) {
            try{
                let response = await axios.get(
                    `data`
                );
                commit('addGames', response.data)
            }catch (e) {
                console.log(e);
            }
        },
        async saveGame({commit}, data){
            let response;
            try{
                response = await axios.post(
                    `data`,
                    {
                        name: data.name,
                        gameId: data.id,
                        gameType: data.type
                    }
                );
                commit('addGame', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async updateGame({commit}, data){
            let response;
            try{
                response = await axios.put(
                    `data/${data._id}`,
                    {
                        name: data.name,
                        gameId: data.id,
                        gameType: data.type
                    }
                );
                commit('updateGame', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async removeGame({commit}, data){
            let response;
            try{
                response = await axios.delete(
                    `data/${data._id}`
                );
                commit('removeGame', data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
    }
};
