import { createStore } from 'vuex'
import {gamesModule} from "@/store/gamesModule";
import {serversModule} from "@/store/serversModule";
import {translationsModule} from "@/store/translationsModule";
import {settingsModule} from "@/store/settingsModule";
import {loginModule} from "@/store/loginModule";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    games: gamesModule,
    servers: serversModule,
    translations: translationsModule,
    settings: settingsModule,
    auth: loginModule
  }
})
