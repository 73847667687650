import axios from "axios";
import router from "../router"

export function setAxiosDefaultURL() {
    axios.defaults.baseURL = process.env.VUE_APP_URL;
    axios.interceptors.response.use(undefined, async (err) => {
        if(err.response && err.response.status === 401){
            const refresh = localStorage.getItem('refresh');
            if(refresh){
                try {
                    const newToken = await axios.post('auth/refresh', {refresh});
                    setAxiosDefaultToken(newToken.data.access);
                    err.config.headers.Authorization = `Bearer ${newToken.data.access}`;
                    return await axios.request(err.config);
                }catch (e) {
                    router.push('/');
                    return Promise.reject(err);
                }
            } else {
                router.push('/');
                return Promise.reject(err);
            }
        }else {
            return Promise.reject(err);
        }
    });
}

export function setAxiosDefaultToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
