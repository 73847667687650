import axios from "axios";
import { findIndex, forEach } from 'lodash'

export const translationsModule = {
    state: () => ({
        translations: []
    }),
    getters: {
        translations: state => state.translations
    },
    mutations: {
        addTranslations(state, translations){
            state.translations = translations
        },
        addNewLanguages(state, translations){
            state.translations= translations
        },
        updateTranslations(state, data) {
            let oldTranslationsIndex = findIndex(state.translations, translations=>translations._id === data._id);
            state.translations[oldTranslationsIndex] = data;
        },
        removeTranslations(state, data){
            let oldTranslationsIndex = findIndex(state.translations, translations=>translations._id === data._id);
            state.translations.splice(oldTranslationsIndex, 1);
        },
        addTranslation(state, translation){
            forEach(translation, trans=>{
                let oldTranslationsIndex = findIndex(state.translations, translations=>translations._id === trans._id);
                if(oldTranslationsIndex>=0){
                    forEach(trans.translations, tr=>{
                        const oldTextIndex = findIndex(state.translations[oldTranslationsIndex].translations, textOld=> textOld.key === tr.key);
                        if(oldTextIndex>=0){
                            state.translations[oldTranslationsIndex].translations[oldTextIndex].text = tr.text
                        }else{
                            state.translations[oldTranslationsIndex].translations.push(tr)
                        }
                    })
                }
            });
        },
        removeTranslation(state, translation){
            state.translations = translation
        },

    },
    actions: {
        async getTranslationsData({commit, getters}) {
            if(getters.currentGame){
                try{
                    let response = await axios.get(
                        `translations/${getters.currentGame._id}`
                    );
                    commit('addTranslations', response.data)
                }catch (e) {
                    console.log(e);
                }
            }else{
                console.log('no game selected')
            }
        },
        async saveLanguages({commit, getters}, data){
            let response;
            try{
                response = await axios.post(
                    `translations/new/${getters.currentGame._id}`,
                    {
                        languages: data.translations
                    }
                );
                commit('addNewLanguages', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async saveTranslation({commit, getters}, data){
            let response;
            try{
                response = await axios.post(
                    `translations/add`,
                    data
                );
                commit('addTranslation', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async updateTranslations({commit}, data){
            let response;
            try{
                response = await axios.put(
                    `translations/${data._id}`,
                    {
                        serverId: data.id,
                    }
                );
                commit('updateServer', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async removeLanguages({commit, getters}, data){
            let response;
            try{
                response = await axios.post(
                    `translations/remove/all/${getters.currentGame._id}`,
                    data
                );
                commit('removeTranslation', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async removeTranslation({commit, getters}, data){
            let response;
            try{
                response = await axios.post(
                    `translations/remove/${getters.currentGame._id}`,
                    {
                        key: data.key
                    }
                );
                commit('removeTranslation', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
    },
};
