import axios from "axios";
import { findIndex } from 'lodash'

export const serversModule = {
    state: () => ({
        servers: []
    }),
    getters: {
        servers: state => state.servers
    },
    mutations: {
        addServers(state, games){
            state.servers = games
        },
        addServer(state, game){
            state.servers.push(game)
        },
        updateServer(state, data) {
            let oldServerIndex = findIndex(state.servers, server=>server._id === data._id);
            state.servers[oldServerIndex] = data;
        },
        removeServer(state, data){
            let oldServerIndex = findIndex(state.servers, server=>server._id === data._id);
            state.servers.splice(oldServerIndex, 1);
        },
    },
    actions: {
        async getServersData({commit, getters}) {
            if(getters.currentGame){
                try{
                    let response = await axios.get(
                        `servers/${getters.currentGame._id}`
                    );
                    commit('addServers', response.data)
                }catch (e) {
                    console.log(e);
                }
            }else{
                console.log('no game selected')
            }
        },
        async saveServer({commit, getters}, data){
            let response;
            try{
                response = await axios.post(
                    `servers/${getters.currentGame._id}`,
                    {
                        name: data.name,
                        serverId: data.id,
                        serverURI: data.uri
                    }
                );
                commit('addServer', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async updateServer({commit}, data){
            let response;
            try{
                response = await axios.put(
                    `servers/${data._id}`,
                    {
                        name: data.name,
                        serverId: data.id,
                        serverURI: data.uri
                    }
                );
                commit('updateServer', response.data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
        async removeServer({commit}, data){
            let response;
            try{
                response = await axios.delete(
                    `servers/${data._id}`
                );
                commit('removeServer', data);
            }catch (e) {
                console.log(e);
            }
            return response
        },
    },
};
