<template>
  <v-app>
    <v-app-bar  theme="dark">
      <top-bar/>
    </v-app-bar>
    <v-navigation-drawer permanent :rail="mini" theme="dark" app>
      <v-list dense>
        <v-list-item @click.stop="mini = !mini" link>
          <v-list-item-icon>
            <v-icon v-show="mini">mdi-chevron-right</v-icon>
            <v-icon v-show="!mini">mdi-chevron-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title></v-list-item-title>
        </v-list-item>
        <v-list-item prepend-icon="mdi-gamepad-variant" title="Games" to="/games" link></v-list-item>
        <v-list-item prepend-icon="mdi-server-network" title="Servers" to="/servers" link></v-list-item>
        <v-list-item prepend-icon="mdi-google-translate" title="Translations" to="/translations" link></v-list-item>
        <v-list-item prepend-icon="mdi-cog" title="Settings" to="/settings" link></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import topBar from "./components/topBar";
import {setAxiosDefaultToken, setAxiosDefaultURL} from "./services/request"

export default {
  name: 'App',
  components: {
    topBar
  },
  data: () => ({
    mini: true
  }),
  mounted(){
    setAxiosDefaultURL();
    setAxiosDefaultToken(localStorage.getItem('access'))
  }
}
</script>
