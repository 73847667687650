<template>
    <div class="games content_wrap">
        <add-game-dialog ref="addGameDialog" @saveGame="add" @updateGame="update"/>
        <delete-game-dialog ref="removeGameDialog" @removeGame="removeGameData"/>
        <v-row align="center" justify="space-between" class="my-5">
            <v-col cols="auto" color="secondary">
                <h1>Games</h1>
            </v-col>
            <v-col cols="4">
                <v-text-field
                        :loading="loading"
                        density="compact"
                        variant="solo"
                        label="Search games"
                        append-inner-icon="mdi-magnify"
                        single-line
                        hide-details
                        @click:append-inner="search"
                ></v-text-field>
            </v-col>
            <v-col cols="auto">
                <v-btn
                        icon="mdi-plus"
                        size="large"
                        @click="openAddNewGameDialog"
                        color="green-darken-1"
                ></v-btn>
            </v-col>
        </v-row>

        <v-table>
            <thead>
            <tr>
                <th class="text-left">
                    Name
                </th>
                <th class="text-left">
                    Id
                </th>
                <th class="text-left">
                    Type
                </th>
                <th class="text-right">
                    Actions
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                    v-for="game in games"
                    :key="game._id"
            >
                <td>{{ game.name }}</td>
                <td>{{ game.gameId }}</td>
                <td>{{ game.gameType }}</td>
                <td>
                    <v-row align="center" justify="end">
                        <v-btn
                                class="mx-2"
                                icon="mdi-pen"
                                size="small"
                                @click="openEditGameDialog(game)"
                        ></v-btn>
                        <v-btn
                                class="mx-2"
                                icon="mdi-cog"
                                size="small"
                                @click="settings(game)"
                        ></v-btn>
                        <v-btn
                                class="mx-2"
                                icon="mdi-google-translate"
                                size="small"
                                @click="translations(game)"
                        ></v-btn>
                        <v-btn
                                class="mx-2"
                                icon="mdi-server-network"
                                size="small"
                                @click="servers(game)"
                        ></v-btn>
                        <v-btn
                                class="mx-2"
                                icon="mdi-delete"
                                size="small"
                                color="red-darken-3"
                                @click="openRemoveGameDialog(game)"
                        ></v-btn>

                    </v-row>

                </td>
            </tr>
            </tbody>
        </v-table>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import addGameDialog from "../components/game/addGameDialog";
    import deleteGameDialog from "../components/game/deleteGameDialog";

    export default {
        name: 'gamesView',
        data: () => ({}),
        components: {addGameDialog, deleteGameDialog},
        methods: {
            ...mapActions(["getGamesData", "saveGame", "updateGame", "removeGame"]),
            ...mapMutations(['selectGame']),
            openAddNewGameDialog() {
                this.$refs.addGameDialog.showDialog();
            },
            async add(data) {
                const res = await this.saveGame(data);
                this.$refs.addGameDialog.close()
            },
            async update(data) {
                const res = await this.updateGame(data);
                this.$refs.addGameDialog.close()
            },
            search() {
                //TODO
                console.log("search")
            },
            async removeGameData(data) {
                const res = await this.removeGame(data);
                this.$refs.removeGameDialog.close()
            },
            //table methods
            openEditGameDialog(gameData) {
                this.$refs.addGameDialog.showDialog(true, gameData);
            },
            openRemoveGameDialog(gameData) {
                this.$refs.removeGameDialog.showDialog(gameData);
            },
            settings(gameData) {
                this.selectGame(gameData);
                this.$router.push({ path: '/settings' })
            },
            translations(gameData) {
                this.selectGame(gameData);
                this.$router.push({ path: '/translations' })
            },
            servers(gameData) {
                this.selectGame(gameData);
              this.$router.push({ path: '/servers' })
            }
        },
        computed: {
            ...mapGetters([
                'games'
            ]),
        },

        mounted() {
            this.getGamesData();
        }
    }
</script>

<style>
    .content_wrap {
        margin: 10px 25px;
    }
</style>
